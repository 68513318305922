var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authed)?_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{ itemsPerPageOptions: [10, 30, 50] },"fixed-header":"","loading":_vm.loading,"loading-text":"加载中..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap px-2 pb-1 pt-3"},[_c('v-text-field',{staticStyle:{"flex":"0 1 auto"},attrs:{"label":"用户名","clearable":"","hide-details":"","dense":"","append-icon":"search"},on:{"click:append":_vm.loadData},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loadData($event)}},model:{value:(_vm.pusername),callback:function ($$v) {_vm.pusername=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"pusername"}}),_c('v-checkbox',{staticClass:"ml-8",attrs:{"hide-details":"","dense":"","label":"显示已停用"},on:{"change":_vm.loadData},model:{value:(_vm.showHidden),callback:function ($$v) {_vm.showHidden=$$v},expression:"showHidden"}})],1),_c('v-divider')]},proxy:true},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticStyle:{"border":"1px solid #ddd"},attrs:{"size":"40"}},[(!!item.avatar)?_c('v-img',{attrs:{"src":item.avatar}}):_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-account-circle")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-square-edit-outline")])]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatFee(item))+" ")]}},{key:"item.duty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDuty(item))+" ")]}},{key:"item.public",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.public ? '✔' : '')+" ")]}},{key:"item.disabled",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.disabled ? '✔' : '')+" ")]}},{key:"footer.prepend",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"large":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.addItem($event)}}},'v-icon',attrs,false),on),[_vm._v("person_add_alt")])]}}],null,false,3042658062)},[_vm._v(" 添加医生 ")])]},proxy:true},{key:"item.signature",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.signature,"large":"","save-text":"保存","cancel-text":"取消"},on:{"update:returnValue":function($event){return _vm.$set(props.item, "signature", $event)},"update:return-value":function($event){return _vm.$set(props.item, "signature", $event)},"save":function($event){return _vm.saveSignature(props.item)},"open":function($event){return _vm.onOpenSignature(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-avatar',{attrs:{"center":"","tile":"","size":"200"}},[(_vm.signature)?_c('v-img',{attrs:{"src":_vm.signature,"contain":""}}):_c('v-icon',{staticClass:"material-icons-outlined",attrs:{"x-large":""}},[_vm._v("note_alt")])],1),_c('v-file-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"选取签名图片文件","label":"签名图片","accept":"image/png"},on:{"change":_vm.onFileChange},model:{value:(_vm.signatureFile),callback:function ($$v) {_vm.signatureFile=$$v},expression:"signatureFile"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.signature ? '有' : '无')+" ")])]}}],null,false,2701629618)}),_c('DoctorEditor',{ref:"dialog",on:{"change":_vm.fetchData}})],1):_c('div',[_vm._v(" 无此权限 ")])}
var staticRenderFns = []

export { render, staticRenderFns }