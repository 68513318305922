<template>
    <div v-if="authed">
        <v-data-table :headers="headers" :items="items"
			:options.sync="options" :server-items-length="totalItems"
			:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header
			:loading="loading" loading-text="加载中...">
			<template v-slot:top>
				<div class="d-flex flex-wrap px-2 pb-1 pt-3">
					<v-text-field label="用户名" v-model.trim="pusername" clearable hide-details dense style="flex: 0 1 auto"
						append-icon="search" @click:append="loadData" @keyup.enter.native="loadData"/>
					<v-checkbox hide-details dense label="显示已停用" v-model="showHidden" @change="loadData" class="ml-8"/>
				</div>
				<v-divider/>
			</template>
            <template v-slot:item.avatar="{ item }">
                <v-avatar size="40" style="border:1px solid #ddd;">
                    <v-img v-if="!!item.avatar" :src="item.avatar"/>
					<v-icon v-else x-large>mdi-account-circle</v-icon>
                </v-avatar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon @click="editItem(item)">mdi-square-edit-outline</v-icon>
            </template>
            <template v-slot:item.fee="{ item }">
                {{formatFee(item)}}
            </template>
            <template v-slot:item.duty="{ item }">
                {{formatDuty(item)}}
            </template>
            <template v-slot:item.public="{ item }">
                {{item.public ? '✔' : ''}}
            </template>
            <template v-slot:item.disabled="{ item }">
                {{item.disabled ? '✔' : ''}}
            </template>
            <template v-slot:footer.prepend>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
		                <v-icon v-bind="attrs" v-on="on" large color="primary" class="ml-1" @click.stop="addItem">person_add_alt</v-icon>
					</template>
					添加医生
				</v-tooltip>
            </template>
            <template v-slot:item.signature="props">
				<v-edit-dialog :return-value.sync="props.item.signature" large save-text="保存" cancel-text="取消"
					@save="saveSignature(props.item)" @open="onOpenSignature(props.item)">
					{{props.item.signature ? '有' : '无'}}
					<template v-slot:input>
						<v-avatar center tile size="200">
							<v-img v-if="signature" :src="signature" contain/>
							<v-icon v-else x-large class="material-icons-outlined">note_alt</v-icon>
						</v-avatar>
						<v-file-input placeholder="选取签名图片文件" label="签名图片" accept="image/png"
							style="width:200px" v-model="signatureFile" @change="onFileChange"/>
					</template>
				</v-edit-dialog>
			</template>
        </v-data-table>
        <DoctorEditor ref="dialog" @change="fetchData"/>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import {duties} from '../workset3'
    import DoctorEditor from "../components/DoctorEditor3.vue"

    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'', value:'avatar', width:64, sortable: false, align:'center'},
                    {text:'', value:'actions', width:32, sortable: false, class:'pa-0', cellClass:'pa-0'},
                    {text:'姓名', value:'name', width:100},
                    {text:'用户名', value:'username', width:100},
//                    {text:'诊所', value:'region', width:150},
                    {text:'职级', value:'title', width:150},
                    {text:'排序', value:'rank', width:80},
                    {text:'业务', value:'duty', width:200, sortable: false},
                    {text:'线上', value:'public', width:80},
                    {text:'停用', value:'disabled', width:80},
                    {text:'签名', value:'signature', width:100},
                ],
                items: [],
                loading: false,
				options: {sortBy:['rank'], sortDesc:[true]},
				totalItems: 0,
				showHidden: false,
				pusername: null,
				signature: null,
				signatureFile: null,
            }
        },
        mounted() {
			this.authed = this.$hasPrivilege('医生管理');
			if (!this.authed) return;
            this.fetchData();
        },
        methods: {
            formatFee(item) {
                return JSON.stringify(item.fee);
            },
			formatDuty(item) {
				return item.duty.map(x => {
					const r = duties.find(y => y.value === x);
					return r ? r.text : '';
				}).join(', ');
			},
			loadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				this.fetchData();
			},
			async fetchData() {
				let filter = {};
				if (!this.showHidden) {
					filter.disabled = false;
				}
				if (this.pusername) {
					filter.username = this.pusername;
				}
				this.loading = true;
				const db = this.$tcbapp.database();
				try {
                    const countRes = await db.collection('wp3doctor').where(filter).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp3doctor').where(filter).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
            editItem(item) {
                this.$refs.dialog.show(item);
            },
            addItem() {
                this.$refs.dialog.show();
            },
			async saveSignature(item) {
                if (this.signatureFile) {
                    const filename = 'wp3/signature/doctor/' + item.username + '.png';
                    const rf = await this.$tcbapp.uploadFile({
//                        config: {env: 'huasan-6gaadaha7ceb62a9'},
                        cloudPath: filename,
                        filePath: this.signatureFile
                    });
					await this.$callCloudFunc({
						funcname:'saveDoctorSignature',
						data: {
							id: item._id,
							cloudfile: rf,
						}
					});
                    item.signature = rf.download_url;
                } else {
					await this.$callCloudFunc({
						funcname:'saveDoctorSignature',
						data: {
							id: item._id,
							cloudfile: null,
						}
					});
                    item.signature = null;
                }
			},
			onOpenSignature(item) {
                this.signature = item.signature;
				this.signatureFile = null;
			},
			onFileChange(e) {
				if (e) {
					this.signatureFile = e;
					this.signature = URL.createObjectURL(e);
				} else {
					this.signatureFile = null;
					this.signature = null;
				}
			},
        },
        components: {
            DoctorEditor
        }
    }
</script>