<template>
    <v-dialog persistent v-model="dialog" max-width="800">
        <v-card>
            <v-card-title>医生信息</v-card-title>
            <v-card-text>
                <v-form ref="formCsl">
                    <v-container>
						<v-row>
							<v-col cols="3" style="text-align:center">
								<v-avatar center size="110" >
									<v-img v-if="avatar" :src="avatar"/>
									<div v-else>
										<v-icon x-large>mdi-account-circle</v-icon>
									</div>
								</v-avatar>
								<v-file-input placeholder="选取一张图片" label="头像" accept="image/jpeg"
									style="width:200px" v-model="avatarFile" @change="onFileChange"/>
							</v-col>
							<v-col cols="9">
								<v-row dense>
									<v-col cols="4"><v-text-field dense label="用户名" v-model.trim="csl.username" :readonly="mode1==='edit'"
										:rules="textRules.concat(usernameRules)" :error-messages="usernameerr" @input="usernameerr=''"/></v-col>
									<v-col cols="4"><v-text-field dense label="姓名" v-model.trim="csl.name" :rules="textRules"/></v-col>
									<v-col cols="4"><v-text-field dense label="排序" v-model.number="csl.rank" :rules="rankRules" type="number" :min="0"/></v-col>
									<v-col cols="4"><v-text-field dense label="职级" v-model.trim="csl.title" :rules="textRules"/></v-col>
									<v-col cols="4"><v-checkbox dense label="线上可见" inset v-model.trim="csl.public"/></v-col>
									<v-col cols="4"><v-checkbox dense label="停用" inset v-model.trim="csl.disabled"/></v-col>
									<v-col cols="12">
										<v-select dense label="业务" :items="duties" multiple chips v-model="csl.duty" :rules="dutyRules" :menu-props="{maxWidth:'400',offsetY:true}">
											<template #selection="{ item }">
												<v-chip :color="item.color" text-color="white">{{item.text}}</v-chip>
											</template>
										</v-select>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
                        <v-row><v-textarea label="专业背景" outlined v-model="csl.career"/></v-row>
                        <v-row><v-textarea label="业务专长" outlined v-model="csl.expertise"/></v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click.stop="save" :loading="loading" :disabled="loading">保存</v-btn>
                <v-btn @click.stop="dialog = false">取消</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {duties} from '../workset3'
import BMF from 'browser-md5-file';
import {promisify} from 'es6-promisify';
const bmf = new BMF;
const md5p = promisify(bmf.md5.bind(bmf));

const ztfee = {"胃肠镜":2500,"胃镜":1000,"肠镜":1500};

export default {
	name: 'DoctorEditor',
	data() {
		return {
			dialog: false,
			csl: {
				username: '',
				name: '',
				avatar: null,
				title: '',
				fee: {},
				career: '',
				expertise: '',
				rank: 500,
				duty: [],
				public: false,
				disabled: false,
			},
			avatar: null,
			avatarFile: null,
			mode1: '',
			duties,
			usernameRules: [v => !!v || '此项为必填', v => (/^[0-9a-zA-Z]*$/g).test(v) || '只允许字母和数字'],
			textRules: [v => !!v || '此项为必填'],
			dutyRules: [v => !!v.length || '此项为必填'],
			rankRules: [v => (/^[0-9]+$/g).test(v) || '只允许数字'],
			usernameerr: '',
			loading: false,
		}
	},
	mounted() {
		const db = this.$tcbapp.database();
	},
	methods: {
		show(csl) {
			if (csl) {
				this.csl = {...csl};
				this.avatar = csl.avatar;
				this.avatarFile = null;
				this.mode1 = 'edit';
			} else {
				this.csl = this.$options.data().csl;
				this.avatarFile = null;
				this.mode1 = 'add';
			}
			this.dialog = true;
			this.$nextTick(() => {
				this.$refs.formCsl.resetValidation();
			});
		},
		onFileChange(e) {
			if (e) {
				this.avatarFile = e;
				this.avatar = URL.createObjectURL(e);
			} else {
				this.avatarFile = null;
				this.avatar = null;
			}
		},
		async saveAvatar(file, username) {
			this.loading = true;
			try {
				const md5 = await md5p(file);
				const filename = 'wp3/avatar/doctor/' + username + '_' + md5 + '.jpg';
				const rf = await this.$tcbapp.uploadFile({
//					config: {env: 'huasan-6gaadaha7ceb62a9'},
					cloudPath: filename,
					filePath: file
				});
				return rf.download_url;
			} catch(err) {
				console.error(err);
				return null;
			} finally {
				this.loading = false;
			}
		},
		async save() {
			if (!this.$refs.formCsl.validate()) return;
			if (this.avatarFile) {
				this.csl.avatar = await this.saveAvatar(this.avatarFile, this.csl.username);
			} else {
				delete this.csl.avatar;
			}
			this.loading = true;
			if (this.mode1 === 'add') {
				try {
					const res = await this.$callCloudFunc({
						funcname:'addDoctor',
						data: {
							doctor: this.csl,
						}
					});
					if (res.result?.err) {
						this.usernameerr = res.result.err;
					} else {
						this.$emit('change');
						this.dialog = false;
					}
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('添加医生失败');
				}
			} else {
				const id = this.csl._id;
				delete this.csl._id;
				delete this.csl._openid;
				try {
					const res = await this.$callCloudFunc({
						funcname:'editDoctor',
						data: {
							id,
							doctor: this.csl,
						}
					});
					this.$emit('change');
					this.dialog = false;
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('修改医生失败');
				}
			}
			this.loading = false;
		}
	}
}
</script>